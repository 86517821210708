<template>
  <div class="equipment-list">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="设备管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="80px" :model="searchData" ref="searchDataRef">
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          style="margin-bottom: 10px"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: '10px' }"
          @change="searchFn"
        />

        <!-- 资产类型 -->
        <ml-select
          prop="propertyType"
          placeholder="请选资产类型"
          :options="propertyTypeList"
          style="margin-bottom: 10px"
          label="资产类型:"
          keyName="label"
          labelName="label"
          valueName="label"
          v-model="searchData.propertyType"
          @change="searchFn"
        />
        <!-- 设备类型 -->
        <ml-select
          prop="deviceType"
          placeholder="请选择设备类型"
          :options="deviceTypeList"
          style="margin-bottom: 10px"
          label="设备类型"
          keyName="label"
          labelName="label"
          valueName="label"
          v-model="searchData.deviceType"
          @change="searchFn"
        />
        <!-- 是否监控-->
        <ml-select
          prop="monitorStatus"
          placeholder="请选择是否监控"
          :options="monitorList"
          style="margin-bottom: 0"
          label="是否监控"
          keyName="label"
          labelName="label"
          valueName="value"
          v-model="searchData.monitorStatus"
          @change="searchFn"
        />

        <!-- 设备名称 -->
        <ml-input
          prop="deviceNameLike"
          placeholder="请输入设备名称"
          style="margin-bottom: 0; margin-right: 40px"
          label="设备名称:"
          :input-style="{ width: '120px' }"
          v-model="searchData.deviceNameLike"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip class="default-tip" style="margin-bottom: 0; border: 0">
      <!-- 新增按钮 -->
      <template #title>
        <div class="tip-header">
          <el-button-group>
            <el-button icon="el-icon-upload2" type="primary" @click="deviceExcelImport">
              批量导入
            </el-button>
            <el-button icon="el-icon-download" type="warning" @click="deviceExcelExport">
              批量导出
            </el-button>
            <el-button icon="el-icon-download" type="warning" @click="iotExport">
              IOT导出
            </el-button>
            <el-button icon="el-icon-delete" type="danger" @click="deviceDeletes">
              批量删除
            </el-button>
          </el-button-group>
          <div>
            <el-button icon="el-icon-plus" type="primary" @click="clickAdd"> 新增设备 </el-button>
            <el-button
              v-if="false"
              icon="el-icon-refresh"
              type="primary"
              @click="clickUpdateEquipment"
            >
              更新产品属性
            </el-button>
          </div>
        </div>
      </template>
      <!-- 表格 -->
      <el-table
        :data="tabberData.data"
        style="width: 100%"
        :height="tabberHeight(390)"
        @select-all="selectAll"
        @select="select"
        @sort-change="sortMethod"
        :row-key="row => row.deviceId"
        ref="equipmentTableRef"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55" />
        <el-table-column
          align="center"
          label="属阿米巴"
          prop="companyName"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="设备名称"
          prop="deviceName"
          min-width="25%"
        />
        <el-table-column align="center" label="设备类型" prop="deviceType" min-width="10%" />
        <el-table-column
          align="center"
          label="所在位置"
          prop="address"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="数量" prop="deviceCount" min-width="10%" />
        <el-table-column align="center" label="资产状态" prop="propertyStatus" min-width="10%" />
        <el-table-column align="center" label="更新时间" prop="updateTime" width="150" />
        <!-- <el-table-column align="center" label="创建时间" prop="createTime" min-width="15%" /> -->
        <el-table-column align="center" label="是否监控" prop="monitorStatus" min-width="10%">
          <template #default="scope">
            <span class="moitor-box" @click="toMonitor(scope.row)">
              <span
                v-if="scope.row.monitorStatus === 'Y'"
                :class="[scope.row.onlineStatus === 'Y' ? 'moitor-green' : 'moitor-red']"
              ></span>
              <span>{{ scope.row.monitorStatus === 'Y' ? '是' : '否' }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="故障记录"
          sortable="errorCount"
          prop="errorCount"
          min-width="12%"
        >
          <template #default="scope">
            <el-button type="text" @click="clickViewFaultRecord(scope.$index, scope.row)">
              {{ scope.row.errorCount }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" priop="deviceId" min-width="35%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-view"
              type="primary"
              @click="clickView(scope.$index, scope.row)"
            >
              查看
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 导入设备提示框 -->
    <ml-dialog width="600px" ref="importEquipmentDialogRef" title="批量导入" :showSubmitBtn="false">
      <template #body>
        <div class="import-equipment-dialog-box">
          <div class="import-equipment-dialog">
            <span>请选择导入的文件:</span>
            <ml-upload
              :showFileList="false"
              ref="importEquipmentManageUploadRef"
              @on-change="onChange"
              accept=".xls, .xlsx"
            >
              <el-button icon="el-icon-upload2" type="primary">批量导入</el-button>
            </ml-upload>
          </div>
          <div class="import-equipment-dialog">
            <span>请导入与部件详情信息表格匹配的文件还无模板？</span>
            <el-button type="text" @click="downloadModel">点击下载模板</el-button>
          </div>
        </div>
      </template>
    </ml-dialog>
    <!-- 删除设备提示框 -->
    <ml-dialog
      ref="deleteEquipmentDialogRef"
      :content="deleteEquipmentData.content"
      @click-submit="submitDeleteEquipment"
    />
    <!-- 批量删除设备提示框 -->
    <ml-dialog
      ref="deleteEquipmentsDialogRef"
      :content="deleteEquipmentsDialogData.content"
      :title="deleteEquipmentsDialogData.title"
      :showSubmitBtn="selectData.length > 0"
      @click-submit="submitDeleteEquipments"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  getPropertyTypeList,
  getUrlParamsString,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { FILEDOWNLOD, DEVICEEXCELEXPORT, DEVICEEXCELTOIOT } from '@API'

export default {
  name: 'EquipmentManage',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const token = computed(() => getters.getAccessToken)
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      deviceNameLike: getMemoryPage.value.searchData.deviceNameLike || '',
      propertyType: getMemoryPage.value.searchData.propertyType || '',
      deviceType: getMemoryPage.value.searchData.deviceType || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      monitorStatus: getMemoryPage.value.searchData.monitorStatus || ''
    })
    // 设备列表
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    // 是否监控
    const monitorList = ref([
      { value: 'Y', label: '是' },
      { value: 'N', label: '否' }
    ])
    // 资产类型
    const propertyTypeList = ref(getPropertyTypeList())
    // 设备类型
    const deviceTypeList = ref([])
    // 监听资产类型变化
    watch(
      () => searchData.propertyType,
      newvalue => {
        deviceTypeList.value = []
        if (newvalue) {
          deviceTypeList.value = propertyTypeList.value.filter(
            item => item.label === newvalue
          )[0].value
        }
      }
    )

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      async newvalue => {
        if (newvalue) {
          await getProjectData(newvalue)
        }
      }
    )
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getEquipmentTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getEquipmentTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取设备列表
    const getEquipmentTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const sort = {}
      if (sortParam.sortName && sortParam.sortOrder) {
        sort.sortName = sortParam.sortName
        sort.sortOrder = sortParam.sortOrder
      }
      const searchDataParams = searchParams(searchData)
      searchDataParams.deviceNameLike = replacePerCent(searchData.deviceNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetDeviceInfos',
        { ...params, ...sort },
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getEquipmentTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 添加设备
    const clickAdd = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/equipmentMAction' })
    }
    // 更新产品属性
    const clickUpdateEquipment = () => {
      dispatch('fetchUpdateIotProducts').then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
        }
      })
    }
    // 修改设备
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/equipmentMAction', query: { updateId: row.deviceId } })
    }
    // 查看设备
    const clickView = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/equipmentMAction', query: { viewId: row.deviceId } })
    }
    const toMonitor = row => {
      if (row.monitorStatus === 'Y') {
        setMemoryPageFn(commit, searchData, tabberData)
        router.push({ path: '/equipmentMMonitor', query: { deviceId: row.deviceId } })
      }
    }
    // 删除设备
    const deleteEquipmentDialogRef = ref()
    const deleteEquipmentData = reactive({
      content: '确定删除该设备？'
    })
    const clickDelete = (index, row) => {
      deleteEquipmentData.deviceId = row.deviceId
      deleteEquipmentDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteEquipment = () => {
      const { deviceId } = deleteEquipmentData
      dispatch('fatchDeleteDeviceInfo', deviceId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getEquipmentTabberData() : (tabberData.page = 1)
          deleteEquipmentDialogRef.value.showDialog = false
        }
      })
    }

    // 下载模板
    const downloadModel = () => {
      window.location.href = `${FILEDOWNLOD}?fileType=device`
    }

    // 导入
    const importEquipmentDialogRef = ref()
    const importEquipmentManageUploadRef = ref()
    const deviceExcelImport = () => {
      importEquipmentDialogRef.value.showDialog = true
    }
    const onChange = (ev, file) => {
      if (file && file.length > 0) {
        const ImportFormData = new FormData()
        ImportFormData.append('file', file[0].raw)
        importEquipmentManageUploadRef.value.CustomUploadRef.uploadFiles = []
        dispatch('fetchDeviceExcelImport', ImportFormData).then(data => {
          if (data && data.code === 200) {
            tabberData.page === 1 ? getEquipmentTabberData() : (tabberData.page = 1)
            importEquipmentDialogRef.value.showDialog = false
          }
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
        })
      }
    }

    // 导出
    const deviceExcelExport = () => {
      const getUrlParams = getUrlParamsString(searchParams(searchData))
      const exportUrl = getUrlParams
        ? `${DEVICEEXCELEXPORT}?token=${encodeURIComponent(
            token.value
          )}&access_token=${encodeURIComponent(token.value)}&${getUrlParams}`
        : `${DEVICEEXCELEXPORT}?token=${encodeURIComponent(
            token.value
          )}&access_token=${encodeURIComponent(token.value)}`
      window.location.href = exportUrl
    }

    // 导出
    const iotExport = () => {
      const getUrlParams = getUrlParamsString(searchParams(searchData))
      const exportUrl = getUrlParams
        ? `${DEVICEEXCELTOIOT}?token=${encodeURIComponent(
            token.value
          )}&access_token=${encodeURIComponent(token.value)}&${getUrlParams}`
        : `${DEVICEEXCELTOIOT}?token=${encodeURIComponent(
            token.value
          )}&access_token=${encodeURIComponent(token.value)}`
      window.location.href = exportUrl
    }

    const clickViewFaultRecord = (index, row) => {
      const { deviceId } = row
      router.push({ name: 'FaultRecord', params: { deviceId } })
    }

    // 选中的添加到列表
    const equipmentTableRef = ref()
    const getSelection = selection => selection.map(item => item.deviceId)
    // 选择指派人列表
    const selectData = ref([])
    // 选中单个
    const select = selection => {
      selectData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = getSelection(selection)
    }
    const deleteEquipmentsDialogRef = ref()
    const deleteEquipmentsDialogData = reactive({
      title: '批量删除',
      content: ''
    })
    // 打批量删除弹出层
    const deviceDeletes = () => {
      if (selectData.value.length <= 0) {
        deleteEquipmentsDialogData.content = '请选择设备'
      } else {
        deleteEquipmentsDialogData.content = '确定删除选中的设备吗？'
      }
      deleteEquipmentsDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteEquipments = () => {
      dispatch('fetchDeleteDeviceInfos', selectData.value).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          selectData.value = []
          equipmentTableRef.value.clearSelection()
          deleteEquipmentsDialogRef.value.showDialog = false
          searchFn()
        }
      })
    }

    const sortable = ref('custom')
    const sortParam = reactive({
      sortName: '',
      sortOrder: ''
    })
    const sortMethod = a => {
      if (a) {
        sortParam.sortName = a.prop
        sortParam.sortOrder = a.order === 'ascending' ? 'asc' : 'desc'
      }
      getEquipmentTabberData({ pageIndex: 1, pageSize: 10 })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (getMemoryPage.value.searchData.companyId) {
        await getProjectData(getMemoryPage.value.searchData.companyId)
      }

      getEquipmentTabberData()
    })
    return {
      equipmentTableRef,
      submitDeleteEquipments,
      deleteEquipmentsDialogRef,
      deleteEquipmentsDialogData,
      selectData,
      deviceDeletes,
      select,
      selectAll,
      unitOptions,
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickAdd,
      clickUpdateEquipment,
      clickUpdate,
      clickDelete,
      deleteEquipmentData,
      deleteEquipmentDialogRef,
      submitDeleteEquipment,
      clickView,
      propertyTypeList,
      deviceTypeList,
      onChange,
      importEquipmentDialogRef,
      importEquipmentManageUploadRef,
      deviceExcelImport,
      downloadModel,
      deviceExcelExport,
      clickViewFaultRecord,
      selectUnit,
      projectData,
      sortMethod,
      sortable,
      iotExport,
      monitorList,
      toMonitor
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment-list {
  @extend %params-global;
}

.import-equipment-dialog-box {
  width: 100%;
  padding: 0 20px;

  .import-equipment-dialog {
    width: 100%;
    display: flex;
    // justify-content: start;
    align-items: center;

    span {
      margin-right: 20px;
    }

    &:first-child {
      margin-bottom: 20px;
    }
  }
}
.moitor-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.moitor-green {
  display: inline-block;
  border-radius: 8px;
  background: #67c23a;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.moitor-red {
  display: inline-block;
  border-radius: 8px;
  background: #f56c6c;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
</style>
